<template>
  <div id="info-rules">
    <p v-html="rulesText"></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "InfoRules",
  components: {
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'roomProfile'
    ]),
    rulesText() {
      return this.roomProfile.motd
    }
  },
  created() {
    if (!this.rulesText) {
      this.$router.push('/room-info');
    }
  },
  methods: {}
}
</script>

<style lang="scss">
#info-rules {
  p {
    margin: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;
  }
  b {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }

  a {
    text-decoration: -webkit-link;
  }
}
</style>
